import React, {useState} from "react";
import {Button, Form} from "antd";

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    onCancel: () => void,
    onBuild: () => void,
    projectName?: string;
}
const Ready: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-ready"
                className="project-form"
            >
                <h2>Ready to build '{props.projectName}'?</h2>
                <p>When you click Build, a new project will be built and available in your dashboard. Once built, you won't be able to change the content of the project.</p>
                <Form.Item {...buttonItemLayout}>
                    <Button type="primary" onClick={props.onBuild}>Build</Button>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button onClick={props.onCancel}>Cancel</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Ready;
