import React, {useMemo} from "react";
import {IQuestionResponses} from "../../services/direct-assess-api/types";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

interface IProps {
    questionResponses: IQuestionResponses | undefined;
}
const ProjectResponsesStackedBarChart: React.FunctionComponent<IProps> = (props) => {
    const options = useMemo(() => {

        const xAxisLabels: string[] = [];
        const dataArray: number[] = [];

        props.questionResponses?.responseValues.forEach((responseValue) => {
            xAxisLabels.push(responseValue.name);
            dataArray.push(Number(responseValue.value));
        });

        return {
            chart: {
                type: 'bar',
            },
            colors: [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ],
            title: {
                text: props.questionResponses?.title
            },
            xAxis: {
                categories: xAxisLabels,
            },
            yAxis: {
                minorTicks: false,
            },
            series: [{
                name: 'Responses',
                data: dataArray,
            }],
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
        };

    }, [props]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}

export default ProjectResponsesStackedBarChart;
