import React, {useEffect} from "react";
import { Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { useRecordWebcam } from 'react-record-webcam';
import {Button} from "antd";

export const RECORD_VIDEO_CUSTOM_TYPE = "record-video-question";

export class QuestionRecordVideoModel extends Question {
    getType() {
        return RECORD_VIDEO_CUSTOM_TYPE;
    }

    get orientation() {
        return this.getPropertyValue("orientation");
    }

    set orientation(val) {
        this.setPropertyValue("orientation", val);
    }

    get resolution() {
        return this.getPropertyValue("resolution");
    }

    set resolution(val) {
        this.setPropertyValue("resolution", val);
    }

    get minRecordLength() {
        return this.getPropertyValue("minRecordLength");
    }

    set minRecordLength(val) {
        this.setPropertyValue("minRecordLength", val);
    }

    get maxRecordLength() {
        return this.getPropertyValue("maxRecordLength");
    }

    set maxRecordLength(val) {
        this.setPropertyValue("maxRecordLength", val);
    }
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    RECORD_VIDEO_CUSTOM_TYPE,
    [
        {
            name: "orientation",
            default: "portrait",
            choices: ["portrait", "landscape"],
            category: "general",
        },
        {
            name: "resolution",
            default: "hd",
            choices: ["hd", "fhd", "2k", "4k"],
            category: "general",
        },
        {
            name: "minRecordLength",
            default: 3,
            category: "general",
        },
        {
            name: "maxRecordLength",
            default: 60,
            category: "general",
        }
    ],
    function () {
        return new QuestionRecordVideoModel("");
    },
    "question"
);

export class SurveyQuestionRecordVideo extends SurveyQuestionElementBase {
    constructor(props: any) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }

    get value() {
        return this.question.value;
    }

    get orientation() {
        return this.question.orientation;
    }

    get resolution() {
        return this.question.resolution;
    }

    get minRecordLength() {
        return this.question.minRecordLength;
    }

    get maxRecordLength() {
        return this.question.maxRecordLength;
    }

    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
        this.question.isDesignMode ? { pointerEvents: "none" } : undefined;
    }

    renderThis(orientation: string, resolution: string, minRecordLength: number, maxRecordLength: number) {
        return (<RecordVideoQuestion orientation={orientation} resolution={resolution} minRecordLength={minRecordLength} maxRecordLength={maxRecordLength} />);
    }

    renderElement() {
        return <div>{this.renderThis(this.orientation, this.resolution, this.minRecordLength, this.maxRecordLength)}</div>;
    }
}

interface IProps {
    orientation: string;
    resolution: string;
    minRecordLength: number;
    maxRecordLength: number;
}

const RecordVideoQuestion: React.FunctionComponent<IProps> = (props) => {
    const {
        activeRecordings,
        cancelRecording,
        clearAllRecordings,
        clearError,
        clearPreview,
        closeCamera,
        createRecording,
        devicesById,
        devicesByType,
        download,
        errorMessage,
        muteRecording,
        openCamera,
        pauseRecording,
        resumeRecording,
        startRecording,
        stopRecording,
    } = useRecordWebcam();

    useEffect(() => {
        let active = true
        load();
        return () => { active = false }

        async function load() {
            const recording = await createRecording();
            if (recording) {
                await openCamera(recording.id);
            }

            if (!active) {
                return
            }
        }
    }, [createRecording, openCamera]);

    return (
      <div className='recorder-container'>
          {activeRecordings?.map((recording) => {
              if (!recording.id.startsWith("undefined")) {
                  return (
                      <div className="bg-white rounded-lg px-4 py-4" key={recording.id}>
                          <div className="text-black grid grid-cols-1">
                              <p>Live</p>
                              <p><small>Status: {recording.status}</small></p>
                              <p><small>Video: {recording.videoLabel}</small></p>
                              <p><small>Audio: {recording.audioLabel}</small></p>
                          </div>
                          <video className='player' ref={recording.webcamRef} loop autoPlay playsInline muted/>
                          <div className="space-x-1 space-y-1 my-2">
                              <Button
                                  disabled={
                                      recording.status === 'RECORDING' ||
                                      recording.status === 'PAUSED'
                                  }
                                  onClick={() => startRecording(recording.id)}
                              >
                                  Record
                              </Button>
                              <Button
                                  disabled={
                                      recording.status !== 'RECORDING' &&
                                      recording.status !== 'PAUSED'
                                  }
                                  onClick={() =>
                                      recording.status === 'PAUSED'
                                          ? resumeRecording(recording.id)
                                          : pauseRecording(recording.id)
                                  }
                              >
                                  {recording.status === 'PAUSED' ? 'Resume' : 'Pause'}
                              </Button>
                              <Button
                                  onClick={() => muteRecording(recording.id)}
                              >
                                  Mute
                              </Button>
                              <Button onClick={() => stopRecording(recording.id)}>
                                  Stop
                              </Button>
                              <Button onClick={() => cancelRecording(recording.id)}>
                                  Cancel
                              </Button>
                          </div>

                          <div
                              className={`${
                                  recording.previewRef.current?.src.startsWith('blob:')
                                      ? 'visible'
                                      : 'hidden'
                              }`}
                          >
                              <p>Preview</p>
                              <video className='player' ref={recording.previewRef} autoPlay playsInline controls/>
                              <div className="space-x-2 my-2">
                                  <Button onClick={() => download(recording.id)}>
                                      Download
                                  </Button>
                                  <Button onClick={() => clearPreview(recording.id)}>
                                      Clear preview
                                  </Button>
                              </div>
                          </div>
                      </div>
                  );
              }

              return null;
          })}
      </div>
    );
}

export default RecordVideoQuestion;
