import React, {useCallback, useState} from "react";
import {Button, Checkbox, Form, FormProps, Select} from "antd";
import {YesNoEnum} from "../../enums/YesNoEnum";
import {IBoardMeetingExecutiveQuestions} from "../../types/types";

const { Option } = Select;

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState: IBoardMeetingExecutiveQuestions | undefined,
    onSubmit: (value: IBoardMeetingExecutiveQuestions) => void,
    complete: boolean,
}

type FieldType = {
    include: YesNoEnum;
    questions: string[];
};

const BoardMeetingReviewExecutiveQuestions: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const [showQuestions, setShowQuestions] = useState<boolean>(false);

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onChange = useCallback((value: string) => {
        if (value === YesNoEnum.Yes) {
            setShowQuestions(true);
        } else {
            setShowQuestions(false);
        }
    }, []);

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        const questionsInOrder = ["preparedness", "quality", "evasiveness", "preFeeling", "postFeeling"];

        const orderedQuestions = questionsInOrder.filter(element => values.questions?.includes(element))

        props.onSubmit({
            include: values.include,
            questions: orderedQuestions,
        });
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-board-meeting-review-executive-questions"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="include" label="Ask extra questions about executive attendees?" rules={[{ required: true, message: 'Please select an option' }]}>
                    <Select
                        placeholder="Select an option"
                        onChange={onChange}
                        value={props.currentState?.include ? props.currentState.include : null}
                        disabled={props.complete}
                    >
                        <Option key={YesNoEnum.Yes} value={YesNoEnum.Yes}>Yes</Option>
                        <Option key={YesNoEnum.No} value={YesNoEnum.No}>No</Option>
                    </Select>
                </Form.Item>
                <div style={{display: showQuestions ? 'block' : 'none'}}>
                    <Form.Item name="questions" label="Which questions do you want to ask about each executive?" rules={[{required: showQuestions, message: 'You need to select at least one question'}]}>
                        <Checkbox.Group disabled={props.complete}>
                            <Checkbox value="preparedness">Preparedness - Well / Adequate / Somewhat / Unprepared</Checkbox>
                            <Checkbox value="quality">Quality of responses - High / Medium / Low</Checkbox>
                            <Checkbox value="evasiveness">Evasiveness - Extremely / Sometimes / Occasionally / No</Checkbox>
                            <Checkbox value="preFeeling">Feeling prior to this Board Meeting - Options to come </Checkbox>
                            <Checkbox value="postFeeling">Feeling after this Board Meeting - Options to come</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default BoardMeetingReviewExecutiveQuestions;
