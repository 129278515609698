import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {ProjectTypeEnum} from "../../enums/ProjectTypeEnum";
import {ProjectInviteeStatusEnum} from "../../enums/ProjectInviteeStatusEnum";
import {PlanLevelEnum} from "../../enums/PlanLevelEnum";
import {FeatureTypeEnum} from "../../enums/FeatureTypeEnum";
import {FeatureStatusEnum} from "../../enums/FeatureStatusEnum";
import {ProjectLinkStatusEnum} from "../../enums/ProjectLinkStatusEnum";
import {IBoardMeetingDetails, IBoardMeetingExecutiveQuestions, IDiscussionTopic} from "../../types/types";
import {ProjectSurveyResultStatusEnum} from "../../enums/ProjectSurveyResultStatusEnum";

enum EntityTag {
    Project = 'PROJECT',
    Personnel = 'PERSONNEL',
    Organisation = 'ORGANISATION',
    DirectorAssessmentSurveyCategory = 'DIRECTOR_ASSESSMENT_SURVEY_CATEGORY',
}

interface IAuth {
    access_token: string;
}

interface IOrganisationPersonnel {
    id: number;
    hash: string;
    firstName: string;
    lastName: string;
    email: string;
    status: GeneralStatusEnum;
    positions: string;
    isDirector?: boolean;
    isExecutive?: boolean;
}

interface ICreateOrganisationPersonnel {
    firstName: string;
    lastName: string;
    email: string;
    positions: string;
}

interface IUpdateOrganisationPersonnel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    status: GeneralStatusEnum;
    positions: string;
}

interface IOrganisation {
    id: number;
    name: string;
    personnel?: IOrganisationPersonnel[];
    organisationFeaturesDto?: string;
    plan: PlanLevelEnum;
}

interface IApiUser {
    id: number;
    email: string;
    roles: string;
    status: GeneralStatusEnum;
    organisation: IOrganisation;
}

interface IUpdateApiUser {
    id: number;
    email: string;
    roles: string;
    status: GeneralStatusEnum;
}

interface ICreateApiUser {
    email: string;
    roles: string;
}

interface ICreateOrganisationWithApiUser {
    organisationName: string;
    firstName: string;
    lastName: string;
    email: string;
}

interface IProjectInvitee {
    id: number;
    hash: string;
    status: ProjectInviteeStatusEnum;
    inviteCount: number;
    typeformLink: string;
    personnel: IOrganisationPersonnel;
    projectLink: string;
}

interface IResponseValues {
    name: string;
    value: string;
}

interface IQuestionResponses {
    title: string;
    responseValues: IResponseValues[];
}

interface IFilledBoardMeetingReviewTopicQuestionResponses {
    questionResponses: IQuestionResponses[];
}

interface IFilledBoardMeetingReviewTopicResponses {
    topic: string;
    presenters: string[];
    filledBoardMeetingReviewTopicQuestionResponses: IFilledBoardMeetingReviewTopicQuestionResponses;
}

interface IFilledDirectorAssessmentDirectorDto {
    directorName: string;
    directorHash: string
}

interface IFilledDirectorAssessmentCriteriaScoreDto {
    name: string;
    criteriaHash: string;
    score: number;
    leadDirector: IFilledDirectorAssessmentDirectorDto;
    proficientDirectors: IFilledDirectorAssessmentDirectorDto;
}

interface IFilledDirectorAssessmentSubCategoryScoreDto {
    subCategoryName: string;
    subCategoryHash: string;
    subCategoryScore: number;
    criteriaScores: IFilledDirectorAssessmentCriteriaScoreDto[];
}

interface IFilledDirectorAssessmentCategoryScoreDto {
    categoryName: string;
    categoryHash: string;
    categoryScore: number;
    subCategoryScores: IFilledDirectorAssessmentSubCategoryScoreDto[];
}

interface IFilledDirectorAssessmentResponsesOverall {
    overallScore: number;
    categoryScores: IFilledDirectorAssessmentCategoryScoreDto[];
}

interface IFilledDirectorAssessmentDirectorCategorySubcategoryPerformanceCategoryDirectorDto {
    directorHash: string;
    directorName: string;
    directorScore: number;
    numberOfLeadSubcategories: number;
    numberOfProficientSubcategories: number;
    numberOfAwareSubcategories: number;
}

interface IFilledDirectorAssessmentDirectorCategorySubcategoryPerformanceCategoryDto {
    categoryHash: string;
    categoryName: string;
    numberOfSubcategories: number;
    directors: IFilledDirectorAssessmentDirectorCategorySubcategoryPerformanceCategoryDirectorDto[];
}

interface IFilledDirectorAssessmentDirectorCategorySubcategoryPerformance {
    categories: IFilledDirectorAssessmentDirectorCategorySubcategoryPerformanceCategoryDto[];
}

interface IFilledDirectorAssessmentSelfRatingDirectorDto {
    directorHash: string;
    directorName: string;
    averageOwnRating: number;
    calculatedScore: number;
    difference: number;
}

interface IFilledDirectorAssessmentSelfRatingCategorySubcategoryCriteriaDto {
    criteriaHash: string;
    criteriaName: string;
    directors: IFilledDirectorAssessmentSelfRatingDirectorDto[];
}

interface IFilledDirectorAssessmentSelfRatingCategorySubcategoryDto {
    subcategoryHash: string;
    subcategoryName: string;
    directors: IFilledDirectorAssessmentSelfRatingDirectorDto[];
    criteria: IFilledDirectorAssessmentSelfRatingCategorySubcategoryCriteriaDto[];
}

interface IFilledDirectorAssessmentSelfRatingCategoryDto {
    categoryHash: string;
    categoryName: string;
    subcategories: IFilledDirectorAssessmentSelfRatingCategorySubcategoryDto[];
}

interface IFilledDirectorAssessmentSelfRating {
    categories: IFilledDirectorAssessmentSelfRatingCategoryDto[];
}

interface IFilledDirectorAssessmentDirectorCriteriaPerformanceDirectorDto {
    directorHash: string;
    directorName: string;
    raw: number;
    scaled: number;
}

interface IFilledDirectorAssessmentDirectorCriteriaPerformanceCriteriaDto {
    criteriaHash: string;
    criteriaName: string;
    meanRaw: number;
    meanScaled: number;
    directors: IFilledDirectorAssessmentDirectorCriteriaPerformanceDirectorDto[];
}

interface IFilledDirectorAssessmentDirectorCriteriaPerformanceCategorySubcategoryDto {
    subcategoryHash: string;
    subcategoryName: string;
    criteria: IFilledDirectorAssessmentDirectorCriteriaPerformanceCriteriaDto[];
}

interface IFilledDirectorAssessmentDirectorCriteriaPerformanceCategoryDto {
    categoryHash: string;
    categoryName: string;
    subcategories: IFilledDirectorAssessmentDirectorCriteriaPerformanceCategorySubcategoryDto[];
}

interface IFilledDirectorAssessmentDirectorCriteriaPerformance {
    categories: IFilledDirectorAssessmentDirectorCriteriaPerformanceCategoryDto[];
}

interface IFilledDirectorAssessmentResponses {
    overall: IFilledDirectorAssessmentResponsesOverall;
    directorCategoryAndSubcategoryPerformance: IFilledDirectorAssessmentDirectorCategorySubcategoryPerformance;
    selfRating: IFilledDirectorAssessmentSelfRating;
    directorCriteriaPerformance: IFilledDirectorAssessmentDirectorCriteriaPerformance;
}

interface IDirectorAssessmentCategoryScore {
    hash: string;
    name: string;
    score: number;
}

interface IFilledResponses {
    numberResponsesExpected: number;
    numberResponsesReceived: number;
    filledBoardMeetingReviewTopicResponses: IFilledBoardMeetingReviewTopicResponses[];
    filledDirectorAssessmentResponses: IFilledDirectorAssessmentResponses;
}

interface IProject {
    id: number;
    createdAt: Date;
    hash: string;
    name: string;
    status: GeneralStatusEnum;
    invitees?: IProjectInvitee[];
    createProjectDto: string;
    filledResponsesDto: IFilledResponses;
}

interface ICreateProject {
    projectType: ProjectTypeEnum;
    projectName: string;
    boardMeetingDetails?: IBoardMeetingDetails;
    topics?: IDiscussionTopic[];
    boardMeetingReviewTopicQuestions?: string[];
    boardMeetingReviewDirectorRatings?: string;
    invitees: string[];
    boardMeetingReviewExecutiveQuestions?: IBoardMeetingExecutiveQuestions;
    directorAssessmentCategories?: string[];
    directorAssessmentCriteria?: any;
    directorAssessmentDirectors?: string[];
    directorAssessmentCriteriaRating?: string;
}

interface ICreateProjectDto {
    projectType: ProjectTypeEnum;
}

interface IFeatureSpecification {
    status: FeatureStatusEnum;
    value: any;
    unlockLevel: PlanLevelEnum;
}

interface IFeature {
    name: FeatureTypeEnum,
    specification: IFeatureSpecification;
}
interface IOrganisationFeatures {
    features: IFeature[];
}

interface IProjectLink {
    redirectUrl: string;
    status: ProjectLinkStatusEnum;
}

interface IProjectSurvey {
    surveyJson: string;
}

interface IProjectSurveyResult {
    result: ProjectSurveyResultStatusEnum;
}

interface ISendInvites {
    projectHash: string,
    inviteeHashes: string[],
}

interface ISurveyCategory {
    id: number,
    position: number,
    hash: string,
    name: string,
    description?: string,
    status: string,
    organisation?: IOrganisation,
}

interface ISurveySubCategory {
    id: number,
    position: number,
    hash: string,
    name: string,
    description?: string,
    status: string,
    organisation?: IOrganisation,
    directorAssessmentCategory: ISurveyCategory,
}

interface ISurveyCategoryQuestion {
    id: number,
    hash: string,
    name: string,
    description?: string,
    type: string,
    status: string,
    organisation?: IOrganisation,
    directorAssessmentCategory: ISurveyCategory,
}

interface ISurveySubCategoryCriteria {
    id: number,
    position: number,
    hash: string,
    name: string,
    description?: string,
    status: string,
    organisation?: IOrganisation,
    directorAssessmentSubCategory: ISurveySubCategory,
}

interface ISurveyCategoryQuestionResponse {
    categoryId: number,
    questions: ISurveyCategoryQuestion[],
}

interface ISurveyCategoriesQuestionsResponse {
    categoryQuestions: ISurveyCategoryQuestionResponse[],
}

interface ICreateSurveyCategoryDto {
    position: number;
    name: string;
    description: string;
    organisationId: number;
}

interface ICreateSurveySubCategoryDto {
    position: number;
    name: string;
    description: string;
    organisationId: number;
    categoryId: number;
}

interface ICreateSurveySubCategoryCriteriaDto {
    position: number;
    name: string;
    description: string;
    organisationId: number;
    subCategoryId: number;
}

interface IEditSurveyCategoryDto {
    id: number;
    position: number;
    name: string;
    description: string;
    organisationId: number;
    status: string;
}

interface IEditSurveySubCategoryDto {
    id: number;
    position: number;
    name: string;
    description: string;
    organisationId: number;
    status: string;
    categoryId: number;
}

interface IEditSurveySubCategoryCriteriaDto {
    id: number;
    position: number;
    name: string;
    description: string;
    organisationId: number;
    status: string;
    subCategoryId: number;
}

export {
    EntityTag,
};

export type {
    IAuth,
    IApiUser,
    IUpdateApiUser,
    ICreateApiUser,
    IResponseValues,
    IQuestionResponses,
    IFilledBoardMeetingReviewTopicResponses,
    IFilledBoardMeetingReviewTopicQuestionResponses,
    IFilledResponses,
    IProject,
    ICreateOrganisationWithApiUser,
    IOrganisationPersonnel,
    ICreateOrganisationPersonnel,
    IUpdateOrganisationPersonnel,
    ICreateProject,
    IProjectInvitee,
    IOrganisation,
    ICreateProjectDto,
    IFeatureSpecification,
    IFeature,
    IOrganisationFeatures,
    IProjectLink,
    IProjectSurvey,
    IProjectSurveyResult,
    ISendInvites,
    ISurveyCategory,
    ISurveySubCategory,
    ISurveyCategoryQuestion,
    ISurveySubCategoryCriteria,
    ISurveyCategoryQuestionResponse,
    ISurveyCategoriesQuestionsResponse,
    ICreateSurveyCategoryDto,
    ICreateSurveySubCategoryDto,
    ICreateSurveySubCategoryCriteriaDto,
    IEditSurveyCategoryDto,
    IEditSurveySubCategoryDto,
    IEditSurveySubCategoryCriteriaDto,
    IDirectorAssessmentCategoryScore,
    IFilledDirectorAssessmentResponsesOverall,
    IFilledDirectorAssessmentCategoryScoreDto,
    IFilledDirectorAssessmentSubCategoryScoreDto,
    IFilledDirectorAssessmentCriteriaScoreDto,
    IFilledDirectorAssessmentDirectorDto,
}
