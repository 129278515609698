import React, {useCallback, useMemo, useState} from "react";
import {
    IEditSurveySubCategoryCriteriaDto,
    ISurveySubCategory,
    ISurveySubCategoryCriteria
} from "../../services/direct-assess-api/types";
import {List, Col, Row, Button, Modal} from "antd";
import {DownOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons";
import {useAuth} from "../../hooks/useAuth";
import DirectorAssessmentCriteriaCreate from "./DirectorAssessmentCriteriaCreate";
import DirectorAssessmentCriteriaEdit from "./DirectorAssessmentCriteriaEdit";
import {useUpdateAdminDirectorAssessmentSurveyCriteriaMutation} from "../../services/direct-assess-api";

interface IProps {
    organisation: string;
    subCategory: ISurveySubCategory | undefined;
    criteria: ISurveySubCategoryCriteria[];
    onRefresh: () => void;
}

const DirectorAssessmentCriteriaList: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [addCriteriaModalOpen, setAddCriteriaModalOpen] = useState<boolean>(false);
    const [editCriteriaModalOpen, setEditCriteriaModalOpen] = useState<boolean>(false);
    const [selectedCriteria, setSelectedCriteria] = useState<ISurveySubCategoryCriteria>();
    const [apiUpdateDirectorAssessmentCriteria, apiUpdateDirectorAssessmentCriteriaResult] = useUpdateAdminDirectorAssessmentSurveyCriteriaMutation();

    const header = useMemo(() => {
        return (
            <Row>
                <Col span={2}>Pos</Col>
                <Col span={16}>Name</Col>
                <Col span={4}>Status</Col>
                <Col span={2}>Actions</Col>
            </Row>
        );
    }, []);

    const sendUpdates = useCallback(async (criteria: ISurveySubCategoryCriteria[]) => {
        // @ts-ignore
        for (const [index, loopCriteria] of criteria.entries()) {
            const payload = {
                id: loopCriteria.id,
                name: loopCriteria.name,
                description: loopCriteria.description,
                position: index + 1,
                organisationId: loopCriteria.organisation ? loopCriteria.organisation.id : undefined,
                status: loopCriteria.status,
                subCategoryId: props.subCategory!.id,
            } as IEditSurveySubCategoryCriteriaDto;

            await apiUpdateDirectorAssessmentCriteria({jwtToken: user.jwtToken, payload: payload});
        }
    }, [apiUpdateDirectorAssessmentCriteria, props.subCategory, user.jwtToken]);

    const moveCriteriaUp = useCallback(async (criteria: ISurveySubCategoryCriteria) => {
        setSelectedCriteria(criteria);

        let indexOfCriteria: number | undefined = undefined;

        const criteriaCopy = [...props.criteria];

        criteriaCopy.forEach((item, index) => {
            if (item.id === criteria.id) {
                indexOfCriteria = index;
            }
        });

        if (indexOfCriteria) {
            criteriaCopy.splice(indexOfCriteria - 1, 0, criteriaCopy.splice(indexOfCriteria, 1)[0]);
        }

        await sendUpdates(criteriaCopy);

        props.onRefresh();
    }, [props, sendUpdates]);

    const moveCriteriaDown = useCallback(async (criteria: ISurveySubCategoryCriteria) => {
        setSelectedCriteria(criteria);

        let indexOfCriteria: number | undefined = undefined;

        const criteriaCopy = [...props.criteria];

        criteriaCopy.forEach((item, index) => {
            if (item.id === criteria.id) {
                indexOfCriteria = index;
            }
        });

        if (indexOfCriteria) {
            criteriaCopy.splice(indexOfCriteria + 1, 0, criteriaCopy.splice(indexOfCriteria, 1)[0]);
        }

        await sendUpdates(criteriaCopy);

        props.onRefresh();
    }, [props, sendUpdates]);

    const editCriteria = useCallback((criteria: ISurveySubCategoryCriteria) => {
        setSelectedCriteria(criteria);
        setEditCriteriaModalOpen(true);
    }, []);

    const criteriaActions = useCallback((criteria: ISurveySubCategoryCriteria) => {
        return (
          <div>
              <Row>
                  <Col span={8}>{criteria.position !== 1 && (<UpOutlined title='Move up' style={{cursor: 'pointer'}} onClick={() => moveCriteriaUp(criteria)} />)}</Col>
                  <Col span={8}>{criteria.position !== props.criteria.length && (<DownOutlined title='Move down' style={{cursor: 'pointer'}} onClick={() => moveCriteriaDown(criteria)} />)}</Col>
                  <Col span={8}><SettingOutlined title='Edit' style={{cursor: 'pointer'}} onClick={() => editCriteria(criteria)} /></Col>
              </Row>
          </div>
        );
    }, [editCriteria, moveCriteriaDown, moveCriteriaUp, props.criteria.length]);

    const onCriteriaClicked = useCallback(async (criteria: ISurveySubCategoryCriteria) => {
        if (selectedCriteria && criteria.id === selectedCriteria.id) {
            setSelectedCriteria(undefined);
        } else {
            setSelectedCriteria(criteria);
        }
    }, [selectedCriteria]);

    const addCriteria = useCallback(() => {
        setAddCriteriaModalOpen(true);
    }, []);

    const onCreateCriteriaClose = useCallback(() => {
        setAddCriteriaModalOpen(false);
        props.onRefresh();
    }, [props]);

    const onEditCriteriaClose = useCallback(() => {
        setEditCriteriaModalOpen(false);
        props.onRefresh();
    }, [props]);

    const nextCriteriaPosition = useMemo(() => {
        let maxPosition = 0;

        if (props.criteria) {
            props.criteria.forEach((criteria) => {
                if (criteria.position > maxPosition) {
                    maxPosition = criteria.position;
                }
            });
        }

        return maxPosition + 1;
    }, [props.criteria]);

    return (
        <div className='director-assessment-category-list'>
            <h2><em>{props.subCategory?.name}</em> criteria</h2>

            <List
                header={header}
                bordered
                dataSource={props.criteria}
                renderItem={(item) => (
                    <List.Item style={{backgroundColor: selectedCriteria?.id === item.id ? 'lightgrey' : 'transparent'}}>
                        <Row style={{width: '100%'}}>
                            <Col style={{cursor: 'pointer'}} onClick={() => onCriteriaClicked(item)}
                                 span={2}>{item.position}</Col>
                            <Col style={{cursor: 'pointer'}} onClick={() => onCriteriaClicked(item)}
                                 span={16}>{item.name}</Col>
                            <Col span={4}>{item.status}</Col>
                            <Col span={2}>{criteriaActions(item)}</Col>
                        </Row>
                    </List.Item>
                )}
            />
            <Button type='primary' className='add-category-button'  onClick={addCriteria}>
                Add Criteria
            </Button>
            <Modal
                open={addCriteriaModalOpen}
                onCancel={onCreateCriteriaClose}
                centered
                footer={null}
                destroyOnClose={true}
            >
                <DirectorAssessmentCriteriaCreate subCategory={props.subCategory!} onClose={onCreateCriteriaClose} position={nextCriteriaPosition} organisation={props.organisation} />
            </Modal>
            <Modal
                open={editCriteriaModalOpen}
                onCancel={onEditCriteriaClose}
                centered
                footer={null}
                destroyOnClose={true}
            >
                <DirectorAssessmentCriteriaEdit onClose={onEditCriteriaClose} criteria={selectedCriteria!} organisation={props.organisation} subCategory={props.subCategory!!}/>
            </Modal>
        </div>
    );
}

export default DirectorAssessmentCriteriaList;
