import React, {useMemo} from "react";
import {IFilledBoardMeetingReviewTopicResponses} from "../../services/direct-assess-api/types";
import ProjectResponsesStackedBarChart from "./ProjectResponsesStackedBarChart";

interface IProps {
    topic: IFilledBoardMeetingReviewTopicResponses | undefined;
}
const BoardMeetingReviewProjectResponsesTopic: React.FunctionComponent<IProps> = (props) => {

    const presenters = useMemo(() => {
        let returnValue = "";

        if (props.topic?.presenters) {
            returnValue = "presented by ";
            let first = true;
            props.topic?.presenters.forEach((presenter) => {
                if (!first) {
                    returnValue += ", ";
                }

                returnValue += presenter;
                first = false;
            });
        }

        return returnValue;
    }, [props]);

    return (
        <div className="project-tab-1">
            <h3>{props.topic?.topic} <span className="no-bold">{presenters}</span></h3>
            {props.topic?.filledBoardMeetingReviewTopicQuestionResponses.questionResponses.map((questionResponses) => {
                return (
                    <ProjectResponsesStackedBarChart questionResponses={questionResponses} />
                );
            })}

        </div>
    );
}

export default BoardMeetingReviewProjectResponsesTopic;
