import React, {useState} from "react";
import {Button, Form, FormProps, Input} from "antd";

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentName?: string,
    onSubmit: (value: string) => void,
    complete: boolean,
}

type FieldType = {
    projectName?: string;
};

const ProjectName: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        if (values.projectName) {
            props.onSubmit(values.projectName);
        }
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-name"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Give your project a name, to easily identify it in your dashboard" name="projectName" rules={[{ required: true, message: 'Please enter a project name'}, { max: 50, message: 'Keep it short!'}]}>
                    <Input disabled={props.complete} value={props.currentName ? props.currentName : undefined} placeholder="Keep it short!" />
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ProjectName;
