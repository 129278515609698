import React, {useCallback, useEffect, useMemo} from "react";
import {Breadcrumb, Layout, Tabs, theme} from "antd";
import {useParams} from "react-router-dom";
import {useGetProjectQuery} from "../services/direct-assess-api";
import {useAuth} from "../hooks/useAuth";
import {ICreateProjectDto, IProject} from "../services/direct-assess-api/types";
import ProjectInvitees from "../components/Project/ProjectInvitees";
import ProjectDefinition from "../components/Project/ProjectDefinition";
import ProjectResponses from "../components/Project/ProjectResponses";
import ProjectInsights from "../components/Project/ProjectInsights";
import {LockOutlined} from "@ant-design/icons";
import ProjectBenchmarks from "../components/Project/ProjectBenchmarks";

const { Content } = Layout;

interface IProps {
}

const ProjectPage: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const { id } = useParams();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, refetch, error} = useGetProjectQuery({jwtToken: user.jwtToken, id: +id!}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const project = useMemo(() => {
        return data ? data as unknown as IProject : undefined;
    }, [data]);

    const createProjectDto: ICreateProjectDto = useMemo(() => {
        return project ? JSON.parse(project.createProjectDto) : undefined;
    }, [project]);
    
    const onRefetch = useCallback(() => {
        refetch();
    }, [refetch]);

    const definitionTab = <ProjectDefinition />;

    const inviteesTab = <ProjectInvitees project={project} invitees={project?.invitees} refetch={onRefetch}/>;

    const responsesTab = <ProjectResponses projectType={createProjectDto?.projectType} filledResponses={project?.filledResponsesDto} />;

    const insightsTab = <ProjectInsights />;

    const benchmarksTab = <ProjectBenchmarks />;

    const tabs = [ {
            label: 'Definition',
            key: 'definition',
            children: definitionTab,
        }, {
            label: 'Invitees',
            key: 'invitees',
            children: inviteesTab,
        }, {
            label: 'Results',
            key: 'responses',
            children: responsesTab,
        }, {
            label: <><LockOutlined /> Insights</>,
            key: 'insights',
            children: insightsTab,
        }, {
            label: <><LockOutlined /> Benchmarks</>,
            key: 'benchmarks',
            children: benchmarksTab,
        }
    ];

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Project</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>Project '{project?.name}'</h2>
                <Tabs
                    defaultActiveKey="invitees"
                    type="card"
                    size="middle"
                    items={tabs}
                />
            </div>
        </Content>
    );
}

export default ProjectPage;
