import React, {useEffect, useMemo, useRef} from "react";
import {Col, Collapse, Row} from "antd";
import CriteriaScores from "./CriteriaScores";
import {IFilledDirectorAssessmentSubCategoryScoreDto} from "../../../services/direct-assess-api/types";
import {Utils} from "../../../Utils/Utils";

interface IProps {
    subCategoryScores: IFilledDirectorAssessmentSubCategoryScoreDto[];
}

const SubCategoryScores: React.FunctionComponent<IProps> = (props) => {
    const componentEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        componentEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, []);

    const items = useMemo(() => {
        return props.subCategoryScores.map((entry) => {
            return {
                key: entry.subCategoryHash,
                label: <Row>
                    <Col span={21}>{entry.subCategoryName}</Col>
                    <Col span={3}><strong>{Utils.limitAndRound((entry.subCategoryScore / 5) * 100, 0, 100, 1)}%</strong></Col>
                </Row>,
                children: <CriteriaScores criteriaScores={entry.criteriaScores} />
            }
        });
    }, [props.subCategoryScores]);

    return (
        <div className='sub-category-scores'>
            <Collapse size="large" items={items}/>
            <div ref={componentEndRef}/>
        </div>
    );
}

export default SubCategoryScores;
