import React, {useMemo, useState} from "react";
import {GetProp, Select} from 'antd';
import {Button, Checkbox, Form, FormProps} from "antd";
import {
    ISurveyCategory,
    ISurveySubCategoryCriteria
} from "../../services/direct-assess-api/types";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];
type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

interface IProps {
    currentState: any,
    onSubmit: (categoryHash: string, categoryCriteria: string[]) => void,
    complete: boolean,
    category: ISurveyCategory,
    availableCriteria: ISurveySubCategoryCriteria[],
}

type FieldType = {
    criteria?: string[];
};

const DirectorAssessmentCategoryCriteria: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const defaultCheckedList = useMemo(() => {
        return props.availableCriteria.map((question) => question.hash);
    }, [props.availableCriteria]);
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(props.currentState ? props.currentState.criteria : defaultCheckedList);

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const formLabel = useMemo(() => {
        return (<div>Which questions within the sub categories of the <strong>{props.category.name}</strong> category do you want to ask?</div>);
    }, [props.category.name]);

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        const inOrder = sortedCriteria.map((criteria) => criteria.hash);

        const toSubmit = inOrder.filter(element => values.criteria?.includes(element));

        props.onSubmit(props.category.hash, toSubmit);
    };

    const sortedCriteria = useMemo(() => {
        return props.availableCriteria.sort((a, b) => a.directorAssessmentSubCategory.position - b.directorAssessmentSubCategory.position);
    }, [props.availableCriteria]);

    let localSubCategory: string;

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name={`project-director-assessment-questions-${props.category.hash}`}
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="criteria" initialValue={checkedList}  label={formLabel} rules={[{required: true, message: 'You need to select at least one question'}]}>
                    <Checkbox.Group disabled={props.complete} style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}} onChange={onChange}>
                        {sortedCriteria.map((criteria) => {

                            let showSubCategory = false;

                            if (criteria.directorAssessmentSubCategory.name !== localSubCategory) {
                                localSubCategory = criteria.directorAssessmentSubCategory.name;
                                showSubCategory = true;
                            }

                            return (
                                <div key={`list-${criteria.id}`}>
                                    {showSubCategory && (
                                        <h3>{localSubCategory}</h3>
                                    )}
                                    <Checkbox style={{margin: '10px 0'}} key={criteria.id} value={criteria.hash}>{criteria.name}</Checkbox>
                                </div>
                            );
                        })}
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DirectorAssessmentCategoryCriteria;
