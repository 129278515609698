import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Select} from "antd";
import {
    useGetAdminDirectorAssessmentSurveyCategoriesMutation,
    useGetAdminOrganisationsQuery,
} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";
import {IOrganisation, ISurveyCategory} from "../../services/direct-assess-api/types";
import DirectorAssessmentCategoryList from "./DirectorAssessmentCategoryList";

interface IProps {
}

const DirectorAssessmentCategories: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [organisation, setOrganisation] = useState<string>('foundation');
    const [categories, setCategories] = useState<ISurveyCategory[] | undefined>(undefined);
    const componentEndRef = useRef<null | HTMLDivElement>(null)

    const {data, error} = useGetAdminOrganisationsQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});
    const [apiGetAdminDirectorAssessmentCategories, apiGetAdminDirectorAssessmentCategoriesResult] = useGetAdminDirectorAssessmentSurveyCategoriesMutation();

    const scrollToBottom = () => {
        componentEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [categories]);

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    useEffect(() => {
        let active = true
        load();
        return () => { active = false }

        async function load() {
            let categoryResult;

            if (organisation) {
                const getAdminDirectorAssessmentCategoriesResponse = await apiGetAdminDirectorAssessmentCategories({jwtToken: user.jwtToken, organisation: organisation});

                if ('data' in getAdminDirectorAssessmentCategoriesResponse) {
                    categoryResult = getAdminDirectorAssessmentCategoriesResponse.data;
                }
            }

            if (!active) {
                return
            }

            setCategories(categoryResult);
        }
    }, [apiGetAdminDirectorAssessmentCategories, organisation, user.jwtToken]);

    const organisations = useMemo(() => {
        return data ? data as unknown as IOrganisation[] : [];
    }, [data]);

    const options = useMemo(() => {
        const returnValue = [{
            value: 'foundation',
            label: <span>Foundation</span>
        }];

        if (organisations) {
            organisations.forEach((organisation) => {
                returnValue.push({
                    value: `${organisation.id}`,
                    label: <span>{organisation.name}</span>,
                })
            });
        }

        return returnValue;
    }, [organisations]);
    
    const loadCategories = useCallback(async () => {
        const getAdminDirectorAssessmentCategoriesResponse = await apiGetAdminDirectorAssessmentCategories({jwtToken: user.jwtToken, organisation: organisation});

        if ('data' in getAdminDirectorAssessmentCategoriesResponse) {
            setCategories(getAdminDirectorAssessmentCategoriesResponse.data);
        }
    }, [apiGetAdminDirectorAssessmentCategories, user.jwtToken, organisation]);

    const onChange = useCallback(async (value: string) => {
        setOrganisation(value);

        await loadCategories();

    }, [loadCategories]);

    return (
        <>
            <div>
                <h2>Director Assessment Categories</h2>

                Select foundation or organisation: <Select value={organisation} className="category-select"
                                                           options={options} onChange={onChange}/>

                {categories && (
                    <DirectorAssessmentCategoryList categories={categories} organisation={organisation}
                                                    onRefresh={loadCategories}/>
                )}

            </div>
            <div ref={componentEndRef} />
        </>
    );
}

export default DirectorAssessmentCategories;
