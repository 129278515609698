import React, {useMemo} from "react";
import {IFilledResponses} from "../../../services/direct-assess-api/types";
import {Table} from "antd";
import {Utils} from "../../../Utils/Utils";
import {IDirector} from "../../../types/types";

const { Column, ColumnGroup } = Table;

interface IProps {
    filledResponses: IFilledResponses | undefined;
}

const DirectorAssessmentProjectSelfRating: React.FunctionComponent<IProps> = (props) => {

    const data = useMemo(() => {
        const returnValue = [];
        if (props.filledResponses?.filledDirectorAssessmentResponses.selfRating.categories) {
            for (const category of props.filledResponses?.filledDirectorAssessmentResponses.selfRating.categories) {

                let entry: any = {
                    key: category.categoryHash,
                    name: category.categoryName,
                    children: [],
                };

                for (const subCategory of category.subcategories) {
                    let subCategoryEntry: any = {
                        key: subCategory.subcategoryHash,
                        name: subCategory.subcategoryName,
                        children: [],
                    }

                    for (const director of subCategory.directors) {
                        const ownString = Utils.limitAndRound(director.averageOwnRating, 0, 5, 1);
                        const calculatedString = Utils.limitAndRound(director.calculatedScore, 0, 5, 1);
                        subCategoryEntry[`${director.directorHash}_own`] = ownString;
                        subCategoryEntry[`${director.directorHash}_calculated`] = calculatedString;
                        subCategoryEntry[`${director.directorHash}_difference`] = Utils.limitAndRound(parseFloat(calculatedString) - parseFloat(ownString), -5, 5, 1);
                    }

                    for (const criteria of subCategory.criteria) {
                        let criteriaEntry: any = {
                            key: criteria.criteriaHash,
                            name: criteria.criteriaName,
                        }

                        for (const director of criteria.directors) {
                            const ownString = Utils.limitAndRound(director.averageOwnRating, 0, 5, 1);
                            const calculatedString = Utils.limitAndRound(director.calculatedScore, 0, 5, 1);
                            criteriaEntry[`${director.directorHash}_own`] = ownString;
                            criteriaEntry[`${director.directorHash}_calculated`] = calculatedString;
                            criteriaEntry[`${director.directorHash}_difference`] = Utils.limitAndRound(parseFloat(calculatedString) - parseFloat(ownString), -5, 5, 1);
                        }

                        subCategoryEntry.children.push(criteriaEntry);
                    }

                    entry.children.push(subCategoryEntry);
                }

                returnValue.push(entry);
            }
        }

        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.selfRating]);

    const directors = useMemo(() => {
        const returnValue: IDirector[] = [];

        if (props.filledResponses?.filledDirectorAssessmentResponses.selfRating.categories[0]) {
            for (const director of props.filledResponses.filledDirectorAssessmentResponses.selfRating.categories[0].subcategories[0].directors) {
                returnValue.push({hash: director.directorHash, name: director.directorName});
            }
        }

        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.selfRating.categories]);

    return (
        <Table dataSource={data} scroll={{ x: 2300 }}>
            <Column title="Category / Subcategory / Criterion" dataIndex="name" key="name" width={500} fixed={true} />
            {directors.map((director) => {
                return (
                    <ColumnGroup title={director.name} key={director.hash} className='director-performance-title'>
                        <Column title="Avg Own Rating" key={`${director.hash}_own`} width={100} align='center' render={(_: any, record: any) => {
                            return (
                                <div>
                                    {record[`${director.hash}_own`] ? record[`${director.hash}_own`] : ''}
                                </div>
                            );
                        }}/>
                        <Column title="Calculated Score" key={`${director.hash}_calculated`} width={100} align='center' render={(_: any, record: any) => {
                            return (
                                <div>
                                    {record[`${director.hash}_calculated`] ? record[`${director.hash}_calculated`] : ''}
                                </div>
                            );
                        }}/>
                        <Column title="Difference" key={`${director.hash}_difference`} width={100} align='center' render={(_: any, record: any) => {
                            return (
                                <div>
                                    {record[`${director.hash}_difference`] ? record[`${director.hash}_difference`] : ''}
                                </div>
                            );
                        }}/>
                    </ColumnGroup>
                );
            })}
        </Table>
    );
}

export default DirectorAssessmentProjectSelfRating;
