import React, {useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import { Button, Form, Grid, Input, theme, Typography, message } from "antd";

import {ExclamationCircleOutlined, LockOutlined, MailOutlined} from "@ant-design/icons";
import {useGetProfileMutation, useLoginMutation} from "../services/direct-assess-api";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const SigninPage: React.FunctionComponent = () => {
    const { login } = useAuth();
    const { token } = useToken();
    const screens = useBreakpoint();
    const [apiLogin, apiLoginResult] = useLoginMutation();
    const [getProfile, apiGetProfileResult] = useGetProfileMutation();
    const [messageApi, contextHolder] = message.useMessage();

    const onLoginError = useCallback(() => {
        messageApi.open({
            type: 'error',
            content: 'Invalid username or password',
            className: 'custom-class',
            style: {
                marginTop: '5vh',
                fontSize: '1.2rem'
            },
            duration: 4,
            icon: <ExclamationCircleOutlined style={{fontSize: '1.2rem', paddingBottom: '0.1rem'}} />
        });
    }, [messageApi]);

    const handleLogin = useCallback(async (values: any) => {
        const authResponse = await apiLogin({email: values.email, password: values.password});

        if ('data' in authResponse) {
            const profileResponse = await getProfile({jwtToken: authResponse.data.access_token});

            if ('data' in profileResponse) {
                login({...profileResponse.data, jwtToken: authResponse.data.access_token});
            }
        } else {
            onLoginError();
        }
    }, [apiLogin, getProfile, login, onLoginError]);

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            width: "100%",
            textAlign: "center",
        },
        forgotPassword: {
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "60vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    // @ts-ignore
    return (
        <section style={styles.section}>
            {contextHolder}
            <div style={styles.container}>
                <div style={{
                    marginBottom: token.marginXL,
                    textAlign: "center",
                }}>
                    <Title style={styles.title}>Sign in</Title>
                    <Text style={styles.text}>
                        Welcome back to ChairTools! Please enter your details below to sign in.
                    </Text>
                </div>
                <Form
                    name="normal_login"
                    onFinish={handleLogin}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: "Please input your Email!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined/>}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined/>}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>

                        </Form.Item>
                        <a style={{float: "right"}} href="/forgot">
                            Forgot password?
                        </a>
                    </Form.Item>
                    <Form.Item style={{marginBottom: "0px"}}>
                        <Button block={true} type="primary" htmlType="submit" disabled={apiLoginResult.isLoading || apiGetProfileResult.isLoading}>
                            Sign in
                        </Button>
                        <div style={{
                            marginTop: token.marginLG,
                            width: "100%",
                            textAlign: "center",
                        }}>
                            <Text style={styles.text}>Don't have an account?</Text>{" "}
                            <Link href="/sign-up">Sign up now</Link>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </section>
    );
}

export default SigninPage;
