import React from "react";
import {IFilledResponses} from "../../../services/direct-assess-api/types";
import DirectorAssessmentProjectResponses from "../DirectorAssessmentResponses/DirectorAssessmentProjectResponses";
import {Tabs} from "antd";
import DirectorAssessmentProjectPerformance from "../DirectorAssessmentResponses/DirectorAssessmentProjectPerformance";
import DirectorAssessmentProjectSelfRating from "../DirectorAssessmentResponses/DirectorAssessmentProjectSelfRating";
import DirectorAssessmentProjectCriteriaPerformance
    from "../DirectorAssessmentResponses/DirectorAssessmentProjectCriteriaPerformance";

interface IProps {
    filledResponses: IFilledResponses | undefined;
}
const DirectorAssessmentResults: React.FunctionComponent<IProps> = (props) => {

    const overallTab = <DirectorAssessmentProjectResponses filledResponses={props.filledResponses} />;

    const selfRatingMatrix = <DirectorAssessmentProjectSelfRating filledResponses={props.filledResponses} />;

    const directorCriteriaPerformance = <DirectorAssessmentProjectCriteriaPerformance filledResponses={props.filledResponses} />;

    const directorCategorySubCategoryPerformance = <DirectorAssessmentProjectPerformance filledResponses={props.filledResponses} />;

    const tabs = [ {
            label: 'Overall',
            key: 'overall',
            children: overallTab,
        }, {
            label: 'Self-rating Matrix',
            key: 'selfratingmatrix',
            children: selfRatingMatrix,
        }, {
            label: 'Director Criteria Performance',
            key: 'directorcriteriaperformace',
            children: directorCriteriaPerformance,
        }, {
            label: 'Director Category & Subcategory Performance',
            key: 'directorcategorysubcategoryperformance',
            children: directorCategorySubCategoryPerformance,
        }
    ];


    return (
        <Tabs
            defaultActiveKey="overallTab"
            type="card"
            size="middle"
            items={tabs}
        />
    );
}

export default DirectorAssessmentResults;
