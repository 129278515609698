import React, {useEffect, useMemo} from "react";
import {Breadcrumb, Layout, Tabs, theme} from "antd";
import {useGetOrganisationQuery} from "../services/direct-assess-api";
import {useAuth} from "../hooks/useAuth";
import {IOrganisation} from "../services/direct-assess-api/types";
import OrganisationDetails from "../components/Organisation/OrganisationDetails";
import OrganisationPersonnel from "../components/Organisation/OrganisationPersonnel";
import OrganisationUsers from "../components/Organisation/OrganisationUsers";

const { Content } = Layout;

interface IProps {
}

const OrganisationPage: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, error} = useGetOrganisationQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const organisation = useMemo(() => {
        return data ? data as unknown as IOrganisation : undefined;
    }, [data]);

    const detailsTab = <OrganisationDetails organisation={organisation!} />;

    const usersTab = <OrganisationUsers />;

    const personnelTab = <OrganisationPersonnel />;

    const tabs = [ {
            label: 'Details',
            key: 'details',
            children: detailsTab,
        }, {
            label: 'Users',
            key: 'users',
            children: usersTab,
        }, {
            label: 'Personnel',
            key: 'personnel',
            children: personnelTab,
        },
    ];

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Organisation</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>{organisation?.name}</h2>
                <Tabs
                    defaultActiveKey="details"
                    type="card"
                    size="middle"
                    items={tabs}
                />
            </div>
        </Content>
    );
}

export default OrganisationPage;
