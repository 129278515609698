
export enum DirectorAssessmentPeopleCultureQuestionsEnum {
    ManageHR = "ManageHR",
    ManageIR = "ManageIR",
    ManageRemuneration = "ManageRemuneration",
    MentoringPM = "MentoringPM",
    SuccessionPlanning = "SuccessionPlanning",
    DiversityInclusion = "DiversityInclusion",
}

