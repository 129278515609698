import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SigninPage from "./pages/SigninPage";
import DashboardPage from "./pages/DashboardPage";
import {UnprotectedLayout} from "./components/UnprotectedLayout";
import {ProtectedLayout} from "./components/ProtectedLayout";
import {AuthProvider} from "./hooks/useAuth";
import ProfilePage from "./pages/ProfilePage";
import SignupPage from "./pages/SignupPage";
import ForgotPage from "./pages/ForgotPage";
import {Provider} from "react-redux";
import {store} from "./store";
import { ConfigProvider } from 'antd';
import NewProjectPage from "./pages/NewProjectPage";
import ProjectPage from "./pages/ProjectPage";
import OrganisationPage from "./pages/OrganisationPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordSetPage from "./pages/PasswordSetPage";
import ProjectLinkPage from "./pages/ProjectLinkPage";
import SurveyJsProjectPage from "./pages/SurveyJsProjectPage";
import {NoLayout} from "./components/NoLayout";
import AdminPage from "./pages/AdminPage";

function App() {
  return (
      <ConfigProvider
          theme={{
              token: {
                  // Seed Token
                  colorPrimary: 'rgb(24, 33, 109)',
                  colorTextBase: 'rgb(24, 33, 109)',
                  fontSize: 18,
                  colorLink: 'rgb(255, 130, 92)',

                  // Alias Token
              },
          }}
      >
          <Provider store={store}>
              <AuthProvider>
                  <Routes>
                      <Route element={<NoLayout />}>
                          <Route path="/project/survey/:projectHash/:inviteeHash" element={<SurveyJsProjectPage />} />
                      </Route>

                      <Route element={<UnprotectedLayout />}>
                          <Route path="/" element={<HomePage />} />
                          <Route path="/sign-in" element={<SigninPage />} />
                          <Route path="/forgot" element={<ForgotPage />} />
                          <Route path="/sign-up" element={<SignupPage />} />
                          <Route path="/password-reset/:resetToken" element={<PasswordResetPage />} />
                          <Route path="/password-set/:resetToken" element={<PasswordSetPage />} />
                          <Route path="/project/go/:projectHash/:inviteeHash" element={<ProjectLinkPage />} />
                      </Route>

                      <Route path="/dashboard" element={<ProtectedLayout />}>
                          <Route path="" element={<DashboardPage />} />
                          <Route path="profile" element={<ProfilePage />} />
                          <Route path="new-project" element={<NewProjectPage />} />
                          <Route path="project/:id" element={<ProjectPage />} />
                          <Route path="organisation" element={<OrganisationPage />} />
                          <Route path="administration" element={<AdminPage />} />
                      </Route>
                  </Routes>
              </AuthProvider>
          </Provider>
      </ConfigProvider>
  );
}

// @ts-ignore
export default App;
