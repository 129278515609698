import {Form, Select} from "antd";
import React, {useCallback, useState} from "react";
import {ProjectTypeEnum} from "../../enums/ProjectTypeEnum";

const { Option } = Select;

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState: ProjectTypeEnum | undefined,
    onChange: (value: ProjectTypeEnum) => void,
    complete: boolean,
}

const TypeSelector: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;

    const onChange = useCallback((value: string) => {
        if (value) {
            props.onChange(value as ProjectTypeEnum);
        }
    }, [props]);

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-type"
                className="project-form"
            >
                <Form.Item name="prjectType" label="What type of project do you want to create?" rules={[{ required: true, message: 'Please select an option' }]}>
                    <Select
                        placeholder="Select an option"
                        onChange={onChange}
                        value={props.currentState ? props.currentState : null}
                        disabled={props.complete}
                    >
                        <Option key={ProjectTypeEnum.BoardMeetingReview} value={ProjectTypeEnum.BoardMeetingReview}>Board Meeting Review</Option>
                        <Option key={ProjectTypeEnum.DirectorAssessment} value={ProjectTypeEnum.DirectorAssessment}>Director Assessments</Option>
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );
}

export default TypeSelector;
