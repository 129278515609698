import React, {useCallback} from "react";
import {Card} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Navigate, useNavigate} from "react-router-dom";

const NewProjectCard: React.FunctionComponent = () => {
    const navigate = useNavigate();

    const onClick = useCallback(() => {
        navigate("/dashboard/new-project", { replace: true });
    }, []);

    return (
        <Card className="new-project-card">
            <h3 style={{cursor: "pointer"}} onClick={onClick}><PlusCircleOutlined /> Add New Project</h3>
        </Card>
    );
}

export default NewProjectCard;
