import React, {useCallback, useMemo, useState} from "react";
import {IProject, IProjectInvitee, ISendInvites} from "../../services/direct-assess-api/types";
import {Button, Table, message} from "antd";
import {ProjectInviteeStatusEnum} from "../../enums/ProjectInviteeStatusEnum";
import {useSendProjectInvitesMutation} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";

interface IProps {
    project: IProject | undefined,
    invitees: IProjectInvitee[] | undefined,
    refetch: () => void,
}

const ProjectInvitees: React.FunctionComponent<IProps> = (props) => {
    const [sendEnabled, setSendEnabled] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [apiSendProjectInvites, apiSendProjectInvitesResult] = useSendProjectInvitesMutation();
    const { user } = useAuth();

    const data = useMemo(() => {
        if (props.invitees) {
            return props.invitees.map((obj) => (
                {...obj, key: obj.id}
            ));
        }
    }, [props.invitees]);

    const onSelectChange = useCallback((selectedRowKeys: React.Key[], selectedRows: any[]) => {
        const enableSend = selectedRows.filter((entry) => (
            entry.status !== "COMPLETE"
            )
        ).length > 0;
        setSelectedRows(selectedRows);
        setSendEnabled(enableSend);
    }, []);
    
    const sendButtonText = useMemo(() => {
        return "Send invite" + (selectedRows.length > 1 ? "s" : "");
    }, [selectedRows]);

    const rowSelection = {
        onChange: onSelectChange,
    };

    const onClickSend = useCallback(async () => {
        const inviteesToSendTo = selectedRows.filter((invitee) => (invitee.status !== "COMPLETE"));
        
        const inviteeHashes = inviteesToSendTo.map((invitee) => {
           return invitee.hash; 
        });
        
        const payload: ISendInvites = {
            projectHash: props.project!.hash,
            inviteeHashes: inviteeHashes,
        }

        const sendProjectInvitesResponse = await apiSendProjectInvites({jwtToken: user.jwtToken, payload: payload});

        if ('data' in sendProjectInvitesResponse) {
            message.success("Project invites sent");
        } else {
            message.error("Looks there was a problem sending invites to one or more invitee");
        }

        props.refetch();
    }, [apiSendProjectInvites, props, selectedRows, user.jwtToken]);

    const columns = [
        {
            title: 'Name',
            dataIndex: [],
            key: 'name',
            render: ((_: string, row: IProjectInvitee) => <>{row.personnel.firstName} {row.personnel.lastName}</>)
        },
        {
            title: 'Link',
            dataIndex: 'projectLink',
            key: 'projectLink',
        },
        {
            title: 'Invite count',
            dataIndex: 'inviteCount',
            key: 'inviteCount',
            render: ((_: string, row: IProjectInvitee) => <>{row.inviteCount === 0 ? '-' : row.inviteCount}</>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ((_: string, row: IProjectInvitee) => {
                switch (row.status) {
                    case ProjectInviteeStatusEnum.Active:
                        return "Invite not sent";
                    case ProjectInviteeStatusEnum.Invited:
                        return "Invite sent";
                    case ProjectInviteeStatusEnum.Complete:
                        return "Completed";
                    case ProjectInviteeStatusEnum.Started:
                        return "Started";
                    default:
                        return "Deleted";
                }
            })
        },
    ];

    return (
        <div className="project-tab">
            <Button className="resend-button" type="primary" onClick={onClickSend} disabled={!sendEnabled} loading={apiSendProjectInvitesResult.isLoading}>
                {sendButtonText}
            </Button>
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                dataSource={data}
                columns={columns}
            />
        </div>
    );
}

export default ProjectInvitees;
