import React, {useMemo, useState} from "react";
import {
    ICreateOrganisationPersonnel,
} from "../../services/direct-assess-api/types";
import {OrganisationPositionEnum} from "../../enums/OrganisationPositionEnum";
import {Button, Form, FormProps, Input, message, Select} from "antd";
import {Utils} from "../../Utils/Utils";
import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {useCreatePersonnelMutation} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    onClose: () => void;
}

type FieldType = {
    firstName: string;
    lastName: string;
    email: string;
    positions: string[];
    status: string;
};

const OrganisationPersonnelCreate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiCreatePersonnel, apiCreatePersonnelResult] = useCreatePersonnelMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            positions: JSON.stringify(values.positions),
        } as ICreateOrganisationPersonnel;

        const createResponse = await apiCreatePersonnel({jwtToken: user.jwtToken, payload: payload});

        if ('data' in createResponse) {
            message.success(`'${payload.firstName} ${payload.lastName}' added`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like adding '${payload.firstName} ${payload.lastName}' failed :(`)
        }
    };

    const positionKeys = useMemo(() => {
        return Object.keys(OrganisationPositionEnum);
    }, []);

    const statusKeys = useMemo(() => {
        return Object.values(GeneralStatusEnum);
    }, []);

    return (
        <>
            <h2>Add personnel</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="personnel-create-form"
                className="personnel-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="First name" name="firstName" rules={[{ required: true, message: 'Please enter first name'}]}>
                    <Input placeholder="First name" />
                </Form.Item>
                <Form.Item<FieldType> label="Last name" name="lastName" rules={[{ required: true, message: 'Please enter last name'}]}>
                    <Input placeholder="Last name" />
                </Form.Item>
                <Form.Item<FieldType> label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address'}]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item<FieldType> label="Positions held" name="positions" rules={[{ required: true, message: 'At least one position should be added', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select the positions held">
                        {positionKeys.map((position, index) => (
                                <Option key={index} value={position}>{`${Utils.readableOrganisationPosition(position as OrganisationPositionEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiCreatePersonnelResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiCreatePersonnelResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default OrganisationPersonnelCreate;
