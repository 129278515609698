import React, {useEffect, useMemo} from "react";
import {Breadcrumb, Col, Layout, Row, theme} from "antd";
import {useGetProjectsQuery} from "../services/direct-assess-api";
import {useAuth} from "../hooks/useAuth";
import NewProjectCard from "../components/NewProjectCard";
import ProjectCard from "../components/ProjectCard";
import {IProject} from "../services/direct-assess-api/types";

const { Content } = Layout;

const DashboardPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, error} = useGetProjectsQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const projects = useMemo(() => {
        return data ? data as unknown as IProject[] : [];
    }, [data]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>Dashboard</h2>
                <Row>
                    {projects.map((project: IProject) =>
                        <Col key={project.id} xs={24} sm={12} md={6} lg={6} xl={6} className="dashboard-column">
                            <ProjectCard project={project} />
                        </Col>
                    )}
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} className="dashboard-column">
                        <NewProjectCard />
                    </Col>
                </Row>
            </div>
        </Content>
    );
}

export default DashboardPage;
