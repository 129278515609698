import React from "react";
import {IFilledResponses} from "../../../services/direct-assess-api/types";
import DummyDirectorAssessmentProjectResponses from "./DummyDirectorAssessmentProjectResponses";
import OverallScore from "./OverallScore";

interface IProps {
    filledResponses: IFilledResponses | undefined;
}
const DirectorAssessmentProjectResponses: React.FunctionComponent<IProps> = (props) => {

    if (!props.filledResponses) {
        return (
          <DummyDirectorAssessmentProjectResponses />
        );
    }

    return (
        <OverallScore filledDirectorAssessmentResponses={props.filledResponses?.filledDirectorAssessmentResponses.overall} />
    );
}

export default DirectorAssessmentProjectResponses;
