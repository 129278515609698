import React, {useMemo} from "react";
import {IFilledResponses} from "../../../services/direct-assess-api/types";
import {Table} from "antd";
import {Utils} from "../../../Utils/Utils";
import {IDirector} from "../../../types/types";

const { Column, ColumnGroup } = Table;

interface IProps {
    filledResponses: IFilledResponses | undefined;
}

const DirectorAssessmentProjectPerformance: React.FunctionComponent<IProps> = (props) => {

    const data = useMemo(() => {
        const returnValue = [];
        if (props.filledResponses?.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance.categories) {
            for (const category of props.filledResponses?.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance.categories) {

                let entry: any = {
                    categoryName: category.categoryName,
                    numberOfSubcategories: category.numberOfSubcategories,
                };

                for (const director of category.directors) {
                    entry[`${director.directorHash}_score`] = Utils.limitAndRound(director.directorScore, 0, 5, 1);
                    entry[`${director.directorHash}_lead`] = director.numberOfLeadSubcategories > 0 ? director.numberOfLeadSubcategories : null;
                    entry[`${director.directorHash}_proficient`] = director.numberOfProficientSubcategories > 0 ? director.numberOfProficientSubcategories : null;
                    entry[`${director.directorHash}_aware`] = director.numberOfAwareSubcategories > 0 ? director.numberOfAwareSubcategories : null;
                }

                returnValue.push(entry);
            }
        }

        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance]);

    const directors = useMemo(() => {
        const returnValue: IDirector[] = [];
        
        if (props.filledResponses?.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance.categories[0]) {
            for (const director of props.filledResponses.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance.categories[0].directors) {
                returnValue.push({hash: director.directorHash, name: director.directorName});
            }
        }
        
        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.directorCategoryAndSubcategoryPerformance.categories]);

    return (
        <Table dataSource={data} scroll={{ x: 2300 }}>
            <Column title="Category" dataIndex="categoryName" key="categoryName" width={300} fixed={true} />
            <Column title="Num SC" dataIndex="numberOfSubcategories" key="numberOfSubcategories" width={100} align='center' fixed={true}/>
            {directors.map((director) => {
                return (
                    <ColumnGroup title={director.name} key={director.hash} className='director-performance-title'>
                        <Column title="Score" dataIndex={`${director.hash}_score`} key={`${director.hash}_score`} width={120} align='center' />
                        <Column title="Num Lead" dataIndex={`${director.hash}_lead`} key={`${director.hash}_lead`} width={120} align='center' render={(_: any, record: any) => (
                            <div>
                                {record[`${director.hash}_lead`] ? record[`${director.hash}_lead`] : '-'}
                            </div>
                            )}
                        />
                        <Column title="Num Proficient" dataIndex={`${director.hash}_proficient`} key={`${director.hash}_proficient`} width={120} align='center' render={(_: any, record: any) => (
                            <div>
                                {record[`${director.hash}_proficient`] ? record[`${director.hash}_proficient`] : '-'}
                            </div>
                            )}
                        />
                        <Column title="Num Aware" dataIndex={`${director.hash}_aware`} key={`${director.hash}_aware`} className='director-performance-subtitle' width={120} align='center' render={(_: any, record: any) => (
                            <div>
                                {record[`${director.hash}_aware`] ? record[`${director.hash}_aware`] : '-'}
                            </div>
                            )}
                        />
                    </ColumnGroup>
                );
            })}
        </Table>
    );
}

export default DirectorAssessmentProjectPerformance;
