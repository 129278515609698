import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IEditSurveyCategoryDto, ISurveyCategory, ISurveySubCategory} from "../../services/direct-assess-api/types";
import {List, Col, Row, Button, Modal} from "antd";
import {DownOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons";
import {
    useGetAdminDirectorAssessmentSurveyCategorySubCategoriesMutation,
    useUpdateAdminDirectorAssessmentSurveyCategoryMutation
} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";
import DirectorAssessmentSubCategoryList from "./DirectorAssessmentSubCategoryList";
import DirectorAssessmentCategoryCreate from "./DirectorAssessmentCategoryCreate";
import DirectorAssessmentCategoryEdit from "./DirectorAssessmentCategoryEdit";

interface IProps {
    organisation: string;
    categories: ISurveyCategory[];
    onRefresh: () => void;
}

const DirectorAssessmentCategoryList: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState<boolean>(false);
    const [editCategoryModalOpen, setEditCategoryModalOpen] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<ISurveyCategory>();
    const [subCategories, setSubCategories] = useState<ISurveySubCategory[]>();
    const [apiGetAdminDirectorAssessmentCategorySubCategories, apiGetAdminDirectorAssessmentCategorySubCategoriesResult] = useGetAdminDirectorAssessmentSurveyCategorySubCategoriesMutation();
    const componentEndRef = useRef<null | HTMLDivElement>(null)
    const [apiUpdateDirectorAssessmentCategory, apiUpdateDirectorAssessmentCategoryResult] = useUpdateAdminDirectorAssessmentSurveyCategoryMutation();

    const scrollToBottom = () => {
        componentEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [subCategories]);

    const header = useMemo(() => {
        return (
            <Row>
                <Col span={2}>Pos</Col>
                <Col span={16}>Name</Col>
                <Col span={4}>Status</Col>
                <Col span={2}>Actions</Col>
            </Row>
        );
    }, []);

    const sendUpdates = useCallback(async (categories: ISurveyCategory[]) => {
        // @ts-ignore
        for (const [index, category] of categories.entries()) {
            const payload = {
                id: category.id,
                name: category.name,
                description: category.description,
                position: index + 1,
                organisationId: category.organisation ? category.organisation.id : undefined,
                status: category.status,
            } as IEditSurveyCategoryDto;

            await apiUpdateDirectorAssessmentCategory({jwtToken: user.jwtToken, payload: payload});
        }
    }, [apiUpdateDirectorAssessmentCategory, user.jwtToken]);

    const moveCategoryUp = useCallback(async (category: ISurveyCategory) => {
        setSelectedCategory(category);

        let indexOfCategory: number | undefined = undefined;

        const categories = [...props.categories];

        categories.forEach((item, index) => {
            if (item.id === category.id) {
                indexOfCategory = index;
            }
        });

        if (indexOfCategory) {
            categories.splice(indexOfCategory - 1, 0, categories.splice(indexOfCategory, 1)[0]);
        }

        await sendUpdates(categories);
        
        props.onRefresh();
    }, [props, sendUpdates]);

    const moveCategoryDown = useCallback(async (category: ISurveyCategory) => {
        setSelectedCategory(category);

        let indexOfCategory: number | undefined = undefined;

        const categories = [...props.categories];

        categories.forEach((item, index) => {
            if (item.id === category.id) {
                indexOfCategory = index;
            }
        });

        if (indexOfCategory) {
            categories.splice(indexOfCategory + 1, 0, categories.splice(indexOfCategory, 1)[0]);
        }

        await sendUpdates(categories);

        props.onRefresh();
    }, [props, sendUpdates]);

    const editCategory = useCallback((category: ISurveyCategory) => {
        setSelectedCategory(category);
        setEditCategoryModalOpen(true);
    }, []);

    const categoryActions = useCallback((category: ISurveyCategory) => {
        return (
          <div>
              <Row>
                  <Col span={8}>{category.position !== 1 && (<UpOutlined title='Move up' style={{cursor: 'pointer'}} onClick={() => moveCategoryUp(category)} />)}</Col>
                  <Col span={8}>{category.position !== props.categories.length && (<DownOutlined title='Move down' style={{cursor: 'pointer'}} onClick={() => moveCategoryDown(category)} />)}</Col>
                  <Col span={8}><SettingOutlined title='Edit' style={{cursor: 'pointer'}} onClick={() => editCategory(category)} /></Col>
              </Row>
          </div>
        );
    }, [editCategory, moveCategoryDown, moveCategoryUp, props.categories.length]);

    const loadSubCategories = useCallback(async (category: ISurveyCategory) => {
        const getAdminDirectorAssessmentCategorySubCategoriesResponse = await apiGetAdminDirectorAssessmentCategorySubCategories({
            jwtToken: user.jwtToken,
            categoryId: category.id
        });

        if ('data' in getAdminDirectorAssessmentCategorySubCategoriesResponse) {
            setSubCategories(getAdminDirectorAssessmentCategorySubCategoriesResponse.data);
        }
    }, [apiGetAdminDirectorAssessmentCategorySubCategories, user.jwtToken]);

    const onCategoryClicked = useCallback(async (category: ISurveyCategory) => {
        if (selectedCategory && category.id === selectedCategory.id) {
            setSelectedCategory(undefined);
            setSubCategories(undefined);
        } else {
            setSelectedCategory(category);
            setSubCategories(undefined);

            await loadSubCategories(category);
        }
    }, [loadSubCategories, selectedCategory]);

    const addCategory = useCallback(() => {
        setAddCategoryModalOpen(true);
    }, []);

    const onCreateCategoryClose = useCallback(() => {
        setAddCategoryModalOpen(false);
        props.onRefresh();
    }, [props]);

    const onEditCategoryClose = useCallback(() => {
        setEditCategoryModalOpen(false);
        props.onRefresh();
    }, [props]);
    
    const nextCategoryPosition = useMemo(() => {
        let maxPosition = 0;

        if (props.categories) {

            props.categories.forEach((category) => {
                if (category.position > maxPosition) {
                    maxPosition = category.position;
                }
            });
        }

        return maxPosition + 1;
    }, [props.categories]);

    return (
        <>
            <div className='director-assessment-category-list'>
                <List
                    header={header}
                    bordered
                    dataSource={props.categories}
                    renderItem={(item) => (
                        <List.Item
                            style={{backgroundColor: selectedCategory?.id === item.id ? 'lightgrey' : 'transparent'}}>
                            <Row style={{width: '100%'}}>
                                <Col style={{cursor: 'pointer'}} onClick={() => onCategoryClicked(item)}
                                     span={2}>{item.position}</Col>
                                <Col style={{cursor: 'pointer'}} onClick={() => onCategoryClicked(item)}
                                     span={16}>{item.name}</Col>
                                <Col span={4}>{item.status}</Col>
                                <Col span={2}>{categoryActions(item)}</Col>
                            </Row>
                        </List.Item>
                    )}
                />
                <Button type='primary' className='add-category-button' onClick={addCategory}>
                    Add Category
                </Button>
                {subCategories && (
                    <DirectorAssessmentSubCategoryList subCategories={subCategories} category={selectedCategory}
                                                       organisation={props.organisation}
                                                       onRefresh={() => loadSubCategories(selectedCategory!)}/>
                )}
                <Modal
                    open={addCategoryModalOpen}
                    onCancel={onCreateCategoryClose}
                    centered
                    footer={null}
                    destroyOnClose={true}
                >
                    <DirectorAssessmentCategoryCreate onClose={onCreateCategoryClose} position={nextCategoryPosition}
                                                      organisation={props.organisation}/>
                </Modal>
                <Modal
                    open={editCategoryModalOpen}
                    onCancel={onEditCategoryClose}
                    centered
                    footer={null}
                    destroyOnClose={true}
                >
                    <DirectorAssessmentCategoryEdit onClose={onEditCategoryClose} organisation={props.organisation}
                                                    category={selectedCategory!}/>
                </Modal>
            </div>
            <div ref={componentEndRef} />
        </>
    );
}

export default DirectorAssessmentCategoryList;
