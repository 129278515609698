import { Suspense } from "react";
import {Navigate, useOutlet} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Footer from "../landing-page/components/Footer";
import Header from "../landing-page/components/Header";
import { Styles } from "../landing-page/styles/styles";

export const UnprotectedLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();

    if (user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <Suspense fallback={null}>
            <Styles />
            <Header />
                {outlet}
            <Footer />
        </Suspense>
    )
};
