import React, {useState} from "react";
import {Button, Checkbox, Form, FormProps} from "antd";

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState: any,
    onSubmit: (value: any) => void,
    complete: boolean,
}

type FieldType = {
    questions?: string[];
};

const BoardMeetingReviewQuestions: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        const inOrder = ["rating", "addressed", "time", "comments"];

        const toSubmit = inOrder.filter(element => values.questions?.includes(element));

        props.onSubmit(toSubmit);
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-board-meeting-review-questions"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="questions" label="Which questions do you want to ask about each meeting topic?" rules={[{required: true, message: 'You need to select at least one question'}]}>
                    <Checkbox.Group disabled={props.complete}>
                        <Checkbox value="rating">Rate each topic - Essential / Important / Acceptable / Unnecessary</Checkbox>
                        <Checkbox value="addressed">How well topic addressed - Satisfactory / Work to do / Unsatisfactory</Checkbox>
                        <Checkbox value="time">Time taken on topic - Appropriate / Needed Longer / Took Too Long</Checkbox>
                        <Checkbox value="comments">Open text comments about topic</Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default BoardMeetingReviewQuestions;
