import React, {useCallback, useMemo} from "react";
import {useParams} from "react-router-dom";
import {
    useGetProjectSurveyQuery,
    useSendProjectSurveyResultMutation
} from "../services/direct-assess-api";
import {IProjectSurvey} from "../services/direct-assess-api/types";
import 'survey-core/defaultV2.min.css';
import {Model, SvgRegistry, FunctionFactory, ElementFactory} from 'survey-core';
import {ReactQuestionFactory, Survey} from 'survey-react-ui';
import { Converter } from 'showdown';
import { iconDescriptionShowSvg, iconDescriptionHideSvg } from "./SurveyIcons";
import * as SurveyTheme from "survey-core/themes";
import {
    RECORD_VIDEO_CUSTOM_TYPE,
    QuestionRecordVideoModel,
    SurveyQuestionRecordVideo
} from "../components/SurveyJsCustom/RecordVideoQuestion";

SvgRegistry.registerIconFromSvg("description-show", iconDescriptionShowSvg);
SvgRegistry.registerIconFromSvg("description-hide", iconDescriptionHideSvg);
const SurveyJsProjectPage: React.FunctionComponent = () => {
    const { projectHash, inviteeHash } = useParams();
    const {data, error} = useGetProjectSurveyQuery({projectHash: projectHash!, inviteeHash: inviteeHash!});
    const [apiSendProjectSurveyResult, apiSendProjectSurveyResultResult] = useSendProjectSurveyResultMutation();


    const surveyJson = useMemo(() => {
        const projectSurvey = data ? data as unknown as IProjectSurvey : undefined;

        if (projectSurvey) {
            return JSON.parse(projectSurvey.surveyJson);
        }

        return undefined;
    }, [data]);

    const onSurveyComplete = useCallback(async (survey: any) => {
        const surveyResultResponse = await apiSendProjectSurveyResult({projectHash: projectHash!, inviteeHash: inviteeHash!, payload: survey.data});

        if ('data' in surveyResultResponse) {

        }
        
    }, [apiSendProjectSurveyResult, inviteeHash, projectHash]);

    const survey = useMemo(() => {
        if (surveyJson) {
            const survey = new Model(surveyJson);

            survey.applyTheme(SurveyTheme.FlatLight);
            survey.setValue("inviteeHash", inviteeHash);
            survey.onComplete.add(onSurveyComplete);

            const converter = new Converter();
            survey.onTextMarkdown.add(function (survey, options) {
                // Convert Markdown to HTML
                let str = converter.makeHtml(options.text);
                // Remove root paragraphs <p></p>
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
                // Set HTML markup to render
                options.html = str;
            });

            /*
            survey.onGetQuestionTitleActions.add((_, options) => {
                options.titleActions = [{
                    id: "show-description",
                    iconName: "icon-description-show",
                    action: (action) => {
                        const q = options.question;
                        q.descriptionLocation = q.descriptionLocation !== "hidden" ? "hidden" : "underTitle";
                        action.iconName = q.descriptionLocation !== "hidden" ? "icon-description-hide" : "icon-description-show";
                    }
                }];
            });*/

            return survey
        }

        return undefined;
    }, [inviteeHash, onSurveyComplete, surveyJson]);

    function getDropdownText(params: any[]) {
        // @ts-ignore
        let dropdown = this.survey.getQuestionByName(params[0]);
        let selectedItem = dropdown.choices.find((x: { selected: any; })=>x.selected);
        if (!!selectedItem)
            return selectedItem.text;
    }

    FunctionFactory.Instance.register("getDropdownText", getDropdownText);

    ElementFactory.Instance.registerElement(RECORD_VIDEO_CUSTOM_TYPE, (name) => {
        return new QuestionRecordVideoModel(name);
    });

    // Register `SurveyQuestionRecordVideo` as a class that renders `record-video-question` questions
    ReactQuestionFactory.Instance.registerQuestion(RECORD_VIDEO_CUSTOM_TYPE, (props) => {
        return React.createElement(SurveyQuestionRecordVideo, props);
    });

    return (
        <>
            {survey && (
                <Survey model={survey} />
            )}
        </>
    );
}

export default SurveyJsProjectPage;