import React from "react";
import LandingPage from "../landing-page/pages/LandingPage";

const HomePage: React.FunctionComponent = () => {
    return (
        <LandingPage />
    );
}

export default HomePage;
