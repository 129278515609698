import React, {useMemo, useState} from "react";
import {Button, Checkbox, Col, Form, FormProps, Row} from "antd";
import {IOrganisationPersonnel} from "../../services/direct-assess-api/types";
import {IBoardMeetingDetails} from "../../types/types";

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState: any,
    onSubmit: (value: any) => void,
    personnel: IOrganisationPersonnel[],
    complete: boolean,
    boardMeetingDetails?: IBoardMeetingDetails,
}

type FieldType = {
    invitees?: string[];
};
const Invitees: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        props.onSubmit(values.invitees);
    };

    const defaultInvitees: string[] = useMemo(() => {
        let returnValue: string[] = [];
        
        if (props.boardMeetingDetails) {
            returnValue = [...(props.boardMeetingDetails.chair ? props.boardMeetingDetails.chair : []), ...(props.boardMeetingDetails.companySecretary ? props.boardMeetingDetails.companySecretary : []), ...(props.boardMeetingDetails.directorsPresent ? props.boardMeetingDetails.directorsPresent : []), ...(props.boardMeetingDetails.invitees ? props.boardMeetingDetails.invitees : [])];
        }

        return returnValue;
    }, [props.boardMeetingDetails]);
    
    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-invitees"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="invitees" label="Who do you want to invite to complete this project?" rules={[{required: true, message: 'You need to select at least one invitee'}]}>
                    <Checkbox.Group style={{ width: '100%' }} disabled={props.complete}>
                        <Row>
                            {props.personnel.map((personnel) => (
                                <Col key={`col_${personnel.id}`} span={8}>
                                    <Checkbox key={personnel.id} value={personnel.hash}>{personnel.firstName} {personnel.lastName}</Checkbox>
                                </Col>
                                )
                            )}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Invitees;
