import React, {useCallback, useState} from "react";

import {BankOutlined, ExclamationCircleOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {useResetPasswordMutation} from "../services/direct-assess-api";

import {Button, Form, Grid, Input, message, theme, Typography} from "antd";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;


const ForgotPage: React.FunctionComponent = () => {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const [hideForm, setHideForm] = useState<boolean>(false);

    const handleResetPassword = useCallback(async (values: any) => {
        await resetPassword({email: values.email});
        setHideForm(true);
    }, [resetPassword]);

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            width: "100%",
            textAlign: "center",
        },
        forgotPassword: {
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "60vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <section style={styles.section}>
            {contextHolder}
            <div style={styles.container}>
                <div style={{display: hideForm ? 'none' : 'block'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <Title style={styles.title}>Forgotten password</Title>
                        <Text style={styles.text}>
                            It's okay, it happens to the best of us. Pop in your registered email address and we'll send you instructions on how to reset your password.
                        </Text>
                    </div>
                    <Form
                        name="normal_forgot"
                        onFinish={handleResetPassword}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    required: true,
                                    message: "Please input your Email",
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined/>}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item style={{marginBottom: "0px"}}>
                            <Button block={true} type="primary" htmlType="submit"
                                    disabled={false}>
                                Reset password
                            </Button>
                            <div style={{
                                marginTop: token.marginLG,
                                width: "100%",
                                textAlign: "center",
                            }}>
                                <Text style={styles.text}>Remembered your password?</Text>{" "}
                                <Link href="/sign-in">Sign in here</Link>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{display: hideForm ? 'block' : 'none'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <Title style={styles.title}>Reset instructions sent!</Title>
                        <Text style={styles.text}>
                            If the email you provided is registered with us, we've sent instruction for password reset to you.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPage;
