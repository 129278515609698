import React, {useMemo, useState} from "react";
import {Button, Checkbox, Form, FormProps} from "antd";
import type { GetProp } from 'antd';
import {ISurveyCategory} from "../../services/direct-assess-api/types";

type LayoutType = Parameters<typeof Form>[0]['layout'];
type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

interface IProps {
    currentState: any,
    onSubmit: (value: any) => void,
    complete: boolean,
    availableCategories: ISurveyCategory[],
}

type FieldType = {
    categories?: string[];
};

const DirectorAssessmentCategories: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const defaultCheckedList = useMemo(() => {
        return props.availableCategories.map((category) => category.hash);
    }, [props.availableCategories]);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(props.currentState.length > 0 ? props.currentState : defaultCheckedList);

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        const inOrder = props.availableCategories.map((category) => category.hash);

        const toSubmit = inOrder.filter(element => values.categories?.includes(element));

        props.onSubmit(toSubmit);
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-director-assessment-categories"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="categories" initialValue={checkedList} label="Which categories do you want to explore? You can only choose to exclude non-foundation categories." rules={[{required: true, message: 'You need to select at least one category'}]}>
                    <Checkbox.Group disabled={props.complete} style={{display: 'flex', flexDirection: 'column', textAlign: 'start'}} onChange={onChange}>
                        {props.availableCategories.map((category) => {
                            return (
                                <Checkbox disabled={!category.organisation} key={category.id} style={{margin: '10px 0'}} value={category.hash}>{category.name}</Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DirectorAssessmentCategories;
