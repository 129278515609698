import {OrganisationPositionEnum} from "../enums/OrganisationPositionEnum";
import {GeneralStatusEnum} from "../enums/GeneralStatusEnum";
import {UserRoleEnum} from "../enums/UserRoleEnum";
import {IApiUser} from "../services/direct-assess-api/types";
import {DirectorAssessmentCategoryEnum} from "../enums/DIrectorAssessmentCategoryEnum";
import {DirectorAssessmentPeopleCultureQuestionsEnum} from "../enums/DirectorAssessmentPeopleCultureQuestionsEnum";
import {DirectorAssessmentCustomerQuestionsEnum} from "../enums/DirectorAssessmentCustomerQuestionsEnum";

export class Utils {
    public static readableOrganisationPosition(position: OrganisationPositionEnum) {
        switch (position) {
            case OrganisationPositionEnum.ChiefExecutiveOfficer:
                return "CEO";
            case OrganisationPositionEnum.ChiefTechnologyOfficer:
                return "CTO";
            case OrganisationPositionEnum.ChiefOperatingOfficer:
                return "COO";
            case OrganisationPositionEnum.ChiefFinancialOfficer:
                return "CFO";
            case OrganisationPositionEnum.ChiefMarketingOfficer:
                return "CMO";
            case OrganisationPositionEnum.CompanySecretary:
                return "Company Secretary";
            case OrganisationPositionEnum.ManagingDirector:
                return "MD";
            case OrganisationPositionEnum.ExecutiveDirector:
                return "ED";
            case OrganisationPositionEnum.NonExecutiveDirector:
                return "NED";
            case OrganisationPositionEnum.IndependentNonExecutiveDirector:
                return "INED";
            case OrganisationPositionEnum.ExecutiveChair:
                return "Executive Chair";
            case OrganisationPositionEnum.Chair:
                return "Chair";
            case OrganisationPositionEnum.MemberOfStaff:
                return "Staff";
            case OrganisationPositionEnum.IndependentContractor:
                return "Contractor";
            default:
                return "Who knows!?";
        }
    }

    public static readableGeneralStatus(status: GeneralStatusEnum) {
        switch (status) {
            case GeneralStatusEnum.Active:
                return "Active";
            case GeneralStatusEnum.Suspended:
                return "Suspended";
            case GeneralStatusEnum.Deleted:
                return "Deleted";
            default:
                return status;
        }
    }

    public static readableUserRole(role: UserRoleEnum) {
        switch (role) {
            case UserRoleEnum.OrganisationAdmin:
                return "Organisation administrator";
            case UserRoleEnum.OrganisationViewer:
                return "Organisation viewer";
            default:
                return role;
        }
    }

    public static userIsOrganisationAdmin(user: IApiUser) {
        const roles = JSON.parse(user.roles);

        return !!roles.includes(UserRoleEnum.OrganisationAdmin);
    }

    public static userIsSuperAdmin(user: IApiUser) {
        const roles = JSON.parse(user.roles);

        return !!roles.includes(UserRoleEnum.SuperAdmin);
    }

    public static percentageFormatter(num: number) {
        return new Intl.NumberFormat('default', {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(num / 100);
    }

    public static DirectorAssessmentCategoryEnumTitle(directorAssessmentCategoryEnum: DirectorAssessmentCategoryEnum) {
        switch (directorAssessmentCategoryEnum) {
            case DirectorAssessmentCategoryEnum.PeopleCulture:
                return "People & Culture";
            case DirectorAssessmentCategoryEnum.Customer:
                return "Customer";
            case DirectorAssessmentCategoryEnum.Reputation:
                return "Reputation";
        }

        return undefined;
    }

    public static DirectorAssessmentPeopleCultureQuestionsEnumTitle(directorAssessmentPeopleCultureQuestionsEnum: DirectorAssessmentPeopleCultureQuestionsEnum) {
        switch (directorAssessmentPeopleCultureQuestionsEnum) {
            case DirectorAssessmentPeopleCultureQuestionsEnum.ManageHR:
                return "Manage Human resources and optimise employee engagement & culture";
            case DirectorAssessmentPeopleCultureQuestionsEnum.ManageIR:
                return "Manage Industrial relations & OH&S compliance and issues";
            case DirectorAssessmentPeopleCultureQuestionsEnum.ManageRemuneration:
                return "Manage remuneration & short-term and long-term incentives strategy and budget to ensure attraction and retention in a cost effective manner";
            case DirectorAssessmentPeopleCultureQuestionsEnum.MentoringPM:
                return "Mentoring & Performance Management of a Co-Founder/CEO & Executive";
            case DirectorAssessmentPeopleCultureQuestionsEnum.SuccessionPlanning:
                return "Oversee succession planning to ensure effective benchstrength for leaders and critical positions";
            case DirectorAssessmentPeopleCultureQuestionsEnum.DiversityInclusion:
                return "Lead and initiate Diversity & Inclusion programs and metrics to improve organisational performance";
        }

        return undefined;
    }

    public static DirectorAssessmentCustomerQuestionsEnumTitle(directorAssessmentCustomerQuestionsEnum: DirectorAssessmentCustomerQuestionsEnum) {
        switch (directorAssessmentCustomerQuestionsEnum) {
            case DirectorAssessmentCustomerQuestionsEnum.CustomerCentric:
                return "Lead a customer centric organisation";
            case DirectorAssessmentCustomerQuestionsEnum.CustomerExperience:
                return "Customer experience design";
            case DirectorAssessmentCustomerQuestionsEnum.CustomerStrategy:
                return "Develop customer strategy at enterprise or x-functional level";
            case DirectorAssessmentCustomerQuestionsEnum.CustomerResearch:
                return "Utilise customer research and analytics to guide business strategy";
        }

        return undefined;
    }

    public static limitAndRound(value: number, min: number, max: number, decimals: number) {
        if (value) {
            const limitedValue = (value < min) ? min : (value > max) ? max : value;

            return limitedValue.toFixed(decimals);
        } else if (value === 0) {
            const limitedValue = 0;

            return limitedValue.toFixed(decimals);
        } else {
            return '-';
        }
    }
}
